import React, { useState, useEffect } from 'react';
import './CardSume.css';

function CardSume() {
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(true);
    const [userName, setUserName] = useState(''); // Stocăm numele utilizatorului

    useEffect(() => {
        const token = localStorage.getItem('token');

        const fetchTotal = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/transactions/total`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                const data = await response.json();
                if (data && data.net_total) {
                    setTotal(data.net_total);
                }
                setLoading(false);
            } catch (error) {
                console.error('Error fetching total transactions:', error);
                setLoading(false);
            }
        };

        const fetchUserName = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/profile/name`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                const data = await response.json();
                if (data && data.name) {
                    setUserName(data.name);
                }
            } catch (error) {
                console.error('Error fetching user name:', error);
            }
        };

        fetchTotal();
        fetchUserName();
    }, []);

    return (
        <div className='card-container'>
            {/*<div className="secondary-card"></div>*/}
            <div className="custom-card">
                <div className="card-header">
                    <span className="card-title">{userName}</span>
                    <span className="card-type">tenger</span>
                </div>
                <div className="card-balance">
                    {loading ? (
                        <p className="loading-text">Loading...</p>
                    ) : (
                        <p className="total-amount">{total.toFixed(2)} MDL</p>
                    )}
                    <div className="color-changing-sphere"></div>
                    <p className="credit-limit">kytfusion corporation</p>
                </div>
                <div className="card-footer">
                    <span className="card-number">**** ****</span>
                    <span className="card-icons">
                </span>
                </div>
                <div className="card-glare"></div>
                <div className="card-pattern"></div>
                <div className="card-light"></div>
            </div>
        </div>
    );

}

export default CardSume;
