import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import HeaderBar from "../../components/headerBar/HeaderBar";
import CardSume from "../../components/cardSume/CardSume";
import './HomeScreen.css';
import MainButton from "../../components/buttons/mainButton/MainButton";
import InfoBlocks from "../../components/infoBlocks/InfoBlocks";
import TransactionsList from "../../components/transactionsList/TransactionsList";

function HomeScreen() {
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');

        if (!token) {
            navigate('/auth');
        }
    }, [navigate]);

    return (
        <div className="home-screen-container">
            <HeaderBar
                title='Wellcome!'
                titleColor="var(--tretyoariColor)"
                leftIcon="faAddressCard"
                rightIcon="faGear"
                showLeftIcon={true}
                showRightIcon={false}
                leftIconColor="var(--tretyoariColor)"
                rightIconColor="#FFFFFF"
                borderColor="var(--tretyoariColor)"
                onLeftIconClick={() => navigate('/profile')}
                onRightIconClick={() => console.log('Right icon clicked')}
            />

            <div className="home-container">
                <CardSume/>
            </div>

            <MainButton
                title="Add Transaction"
                textColor="var(--mainTextColor)"
                bgColor="var(--mainColor)"
                onClick={() => navigate('/transaction')}
            />

            <InfoBlocks/>

            <TransactionsList/>
        </div>
    );
}

export default HomeScreen;
