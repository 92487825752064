import React, { useState } from 'react';
import './IncomeExpenseToggle.css';

function IncomeExpenseToggle({ onToggle }) {
    const [selected, setSelected] = useState('income');

    const handleToggle = (option) => {
        setSelected(option);
        onToggle(option);
    };

    return (
        <div className="toggle-container">
            <div className={`toggle-slider ${selected === 'expense' ? 'slide-right' : 'slide-left'}`} />

            <div
                className={`toggle-option ${selected === 'income' ? 'active' : ''}`}
                onClick={() => handleToggle('income')}
            >
                Income
            </div>
            <div
                className={`toggle-option ${selected === 'expense' ? 'active' : ''}`}
                onClick={() => handleToggle('expense')}
            >
                Expense
            </div>
        </div>
    );
}

export default IncomeExpenseToggle;
