import { useEffect } from "react";

export function useThemeDetection(setIsDarkMode) {
    useEffect(() => {
        const matchMedia = window.matchMedia('(prefers-color-scheme: dark)');

        const updateThemeColor = (isDarkMode) => {
            const root = document.documentElement;

            root.style.setProperty('--appBackgroundColor', isDarkMode ? '#00140c' : '#FFFFFF');
            root.style.setProperty('--inputBackgroundColor', isDarkMode ? '#FFFFFF' : 'rgb(216, 240, 231)');
            setIsDarkMode(isDarkMode); // Set the theme mode
        };

        updateThemeColor(matchMedia.matches);

        const handleThemeChange = (e) => {
            updateThemeColor(e.matches);
        };

        matchMedia.addEventListener('change', handleThemeChange);

        return () => {
            matchMedia.removeEventListener('change', handleThemeChange);
        };
    }, [setIsDarkMode]); // Ensure it updates when setIsDarkMode changes
}
