import React, {useEffect} from 'react';
import MainButton from "../../components/buttons/mainButton/MainButton";
import HeaderBar from "../../components/headerBar/HeaderBar";
import {useNavigate} from 'react-router-dom';
import './AuthenticationStyle.css';
import {checkTokenAuth} from "../../scripts/VerifyAuth";

function AuthenticationScreen() {
    const navigate = useNavigate();

    useEffect(() => {
        checkTokenAuth(navigate);
    }, [navigate]);

    return (
        <div className="authentication-screen">
            <HeaderBar
                leftIcon="faChevronLeft"
                showLeftIcon={false}
                leftIconColor="var(--mainColor)"
                onLeftIconClick={() => console.log('Left icon clicked')}

                title="Authentication"
                titleColor="var(--mainColor)"

                rightIcon="faGear"
                showRightIcon={false}
                rightIconColor="var(--mainColor)"
                onRightIconClick={() => console.log('Right icon clicked')}
            />

            <img src={`${process.env.PUBLIC_URL}/authscreen1.png`} alt="logo" className="intro-screen-img"/>

            <div className="logo-container">
                <img src={`${process.env.PUBLIC_URL}/logo3.png`} alt="logo" className="auth-screen-logo"/>
                <p className="app-title">Tenger</p>
            </div>

            <div className="auth-bottom-container">
                <MainButton
                    title="Login"
                    textColor="var(--mainTextColor)"
                    bgColor="var(--mainColor)"
                    onClick={() => navigate('/login')}
                />

                <div style={{height: '30px'}}/>

                <MainButton
                    title="Register"
                    textColor="var(--mainTextColor)"
                    bgColor="var(--tretyoariColor)"
                    onClick={() => navigate('/register')}
                />
            </div>
        </div>
    );
}

export default AuthenticationScreen;
