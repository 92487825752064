import React from 'react';
import './MainInput.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLock, faUser, faTag, faDollarSign } from '@fortawesome/free-solid-svg-icons';

const iconMap = {
    faEnvelope: faEnvelope,
    faLock: faLock,
    faUser: faUser,
    faTag: faTag,
    faDollarSign: faDollarSign,
};

function MainInput({ icon, label, value, onChange, type = 'text' }) {
    return (
        <div className="main-button-container">
            <FontAwesomeIcon icon={iconMap[icon]} className="input-icon" />
            <input
                type={type}
                className="styled-input"
                placeholder={label}
                value={value}
                onChange={onChange}
            />
        </div>
    );
}

export default MainInput;
