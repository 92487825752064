import React, {useEffect} from 'react';
import './Modal.css';

function Modal({message, onClose}) {
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (onClose) {
                onClose();
            }
        }, 2000);

        return () => clearTimeout(timeoutId);
    }, [onClose]);

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    return (
        <div className="modal-overlay" onClick={handleOverlayClick}>
            <div className="modal-content">
                <p>{message}</p>
                <button onClick={onClose} className="modal-close-button">Close</button>
            </div>
        </div>
    );
}

export default Modal;
