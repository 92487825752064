export const handleLogin = async (email, password, navigate, setErrorMessage) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: email,
                password: password,
            }),
        });

        const data = await response.json();

        if (!response.ok) {
            const errorMsg = typeof data.error === 'string' ? data.error : JSON.stringify(data.error);
            setErrorMessage(errorMsg || 'An unexpected error occurred.');
            return;
        }

        if (data.token) {
            localStorage.setItem('token', data.token);
            navigate('/home');
        } else {
            setErrorMessage('An unexpected error occurred.');
        }
    } catch (error) {
        setErrorMessage('Network error occurred.');
    }
};
