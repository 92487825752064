import React from 'react';
import { useNavigate } from 'react-router-dom';
import './InfoBlocks.css';

function InfoBlocks() {
    const navigate = useNavigate();

    const handleNavigateToStatistics = () => {
        navigate('/statistics/monthly-daily');
    };

    const handleNavigateToTypesStatistics = () => {
        navigate('/statistics/types');
    };

    return (
        <div className="info-blocks-container">
            <div className="info-block blue-block" onClick={handleNavigateToStatistics}>
                <div className="block-content">
                    <div className="icon">
                        <svg width="60" height="40" viewBox="0 0 60 40">
                            <polyline points="5,30 20,15 35,20 50,10" fill="none" stroke="#fff" strokeWidth="2" />
                            <circle cx="5" cy="30" r="3" fill="#fff" />
                            <circle cx="20" cy="15" r="3" fill="#fff" />
                            <circle cx="35" cy="20" r="3" fill="#fff" />
                            <circle cx="50" cy="10" r="3" fill="#fff" />
                        </svg>
                    </div>
                    <h3>Monthly and daily statistics</h3>
                </div>
            </div>

            <div className="info-block green-block" onClick={handleNavigateToTypesStatistics}>
                <div className="block-content">
                    <div className="icon">
                        <svg width="60" height="60" viewBox="0 0 32 32">
                            <circle cx="16" cy="16" r="15" fill="#fff" />
                            <path d="M16 16 L16 1 A15 15 0 0 1 31 16 Z" fill="#4a6edb" />
                        </svg>
                    </div>
                    <h3>Statistics by types</h3>
                </div>
            </div>
        </div>
    );
}

export default InfoBlocks;
