import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import 'chart.js/auto';

export default function CategoryPieChart() {
    const [data, setData] = useState({
        labels: [],
        datasets: [
            {
                label: 'Category Sums',
                data: [],
                backgroundColor: [],
                hoverOffset: 6,
            },
        ],
    });

    const generateRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };

    useEffect(() => {
        const token = localStorage.getItem('token');

        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/transactions/category`, {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                // Verificăm dacă răspunsul este ok (status 200-299)
                if (!response.ok) {
                    console.error(`Error fetching data: ${response.status} ${response.statusText}`);
                    return;
                }

                // Încercăm să parsăm răspunsul ca JSON
                const result = await response.json();

                const categories = result.category_sums;
                const labels = categories.map(item => item.label);
                const values = categories.map(item => item.value);
                const colors = categories.map(() => generateRandomColor());

                setData({
                    labels: labels,
                    datasets: [
                        {
                            label: 'Category Sums',
                            data: values,
                            backgroundColor: colors,
                            hoverBackgroundColor: colors.map(color => color + 'B3'),
                            borderColor: '#fff',
                            borderWidth: 2,
                            hoverOffset: 8,
                        },
                    ],
                });
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);


    return (
        <div style={{
            margin:'70px 30px',
            width: '340px',
            height: '500px',
            backgroundColor: '#f9f9f9',
            borderRadius: '15px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        }}>
            <Pie
                data={data}
                options={{
                    plugins: {
                        legend: {
                            position: 'bottom',
                            labels: {
                                boxWidth: 15,
                                padding: 15,
                                font: {
                                    size: 14,
                                },
                                color: '#333',
                            },
                        },
                        tooltip: {
                            backgroundColor: '#333',
                            titleColor: '#fff',
                            bodyColor: '#fff',
                            boxPadding: 5,
                            callbacks: {
                                label: function (context) {
                                    let label = context.label || '';
                                    if (label) {
                                        label += ': ';
                                    }
                                    label += context.raw;
                                    return label;
                                }
                            }
                        }
                    },
                    maintainAspectRatio: false,
                    responsive: true,
                }}
            />
        </div>
    );
}
