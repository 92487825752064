import React, { useEffect, useState } from 'react';
import MainButton from "../../components/buttons/mainButton/MainButton";
import HeaderBar from "../../components/headerBar/HeaderBar";
import { useNavigate } from 'react-router-dom';
import MainInput from "../../components/mainInput/MainInput";
import './LoginStyle.css';
import Modal from "../../components/modal/Modal";
import { handleLogin } from './LoginScript';
import { checkTokenHome } from "../../scripts/VerifyAuth";

function LoginScreen() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        checkTokenHome(navigate);
    }, [navigate]);

    return (
        <div className="login-center-container">
            <HeaderBar
                leftIcon="faAngleLeft"
                showLeftIcon={true}
                leftIconColor="var(--mainColor)"
                onLeftIconClick={() => navigate(-1)}

                title="Login"
                titleColor="var(--mainColor)"

                rightIcon="faGear"
                showRightIcon={false}
                rightIconColor="var(--mainColor)"
                onRightIconClick={() => console.log('Right icon clicked')}
            />

            <img src={`${process.env.PUBLIC_URL}/loginscreen1.png`} alt="logo" className="intro-screen-img"/>

            <div className="logo-container">
                <img src={`${process.env.PUBLIC_URL}/logo3.png`} alt="logo" className="auth-screen-logo"/>
                <p className="app-title">Tenger</p>
            </div>

            <div className="login-bottom-container">
                <MainInput
                    icon="faEnvelope"
                    label="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />

                <div style={{ height: '30px' }} />

                <MainInput
                    icon="faLock"
                    label="Password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />

                <div style={{ height: '30px' }} />

                {errorMessage && <Modal message={errorMessage} onClose={() => setErrorMessage('')}/>}

                <MainButton
                    title="Login"
                    textColor="var(--mainTextColor)"
                    bgColor="var(--mainColor)"
                    onClick={() => handleLogin(email, password, navigate, setErrorMessage)} // Apelează funcția de login
                />
            </div>
        </div>
    );
}

export default LoginScreen;
