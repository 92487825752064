import React, {useEffect, useState} from 'react';
import MainButton from "../../components/buttons/mainButton/MainButton";
import HeaderBar from "../../components/headerBar/HeaderBar";
import {useNavigate} from 'react-router-dom';
import MainInput from "../../components/mainInput/MainInput";
import './RegisterStyle.css';
import Modal from "../../components/modal/Modal";
import {handleRegisterRequest} from "./RegisterScript";
import {checkTokenHome} from "../../scripts/VerifyAuth";

function RegisterScreen() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        checkTokenHome(navigate);
    }, [navigate]);

    const handleRegister = () => {
        handleRegisterRequest(name, email, password, navigate, setErrorMessage);
    };

    return (
        <div className="register-center-container">
            <HeaderBar
                leftIcon="faAngleLeft"
                showLeftIcon={true}
                leftIconColor="var(--mainColor)"
                onLeftIconClick={() => navigate(-1)}

                title="Registration"
                titleColor="var(--mainColor)"

                rightIcon="faGear"
                showRightIcon={false}
                rightIconColor="var(--mainColor)"
                onRightIconClick={() => console.log('Right icon clicked')}
            />

            <img src={`${process.env.PUBLIC_URL}/registerscreen1.png`} alt="logo" className="intro-screen-img"/>

            <div className="logo-container">
            <img src={`${process.env.PUBLIC_URL}/logo3.png`} alt="logo" className="auth-screen-logo"/>
                <p className="app-title">Tenger</p>
            </div>

            <div className="register-bottom-container">
                <MainInput
                    icon="faUser"
                    label="Username"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />

                <div style={{height: '20px'}}/>

                <MainInput
                    icon="faEnvelope"
                    label="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />

                <div style={{height: '20px'}}/>

                <MainInput
                    icon="faLock"
                    label="Password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />

                <div style={{height: '20px'}}/>

                {errorMessage && <Modal message={errorMessage} onClose={() => setErrorMessage('')}/>}

                <MainButton
                    title="Registration"
                    textColor="var(--mainTextColor)"
                    bgColor="var(--mainColor)"
                    onClick={handleRegister}
                />
            </div>
        </div>
    );
}

export default RegisterScreen;
