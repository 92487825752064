import React, { useState, useEffect } from 'react';
import './AppStyle.css';
import { Route, Routes, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import AuthenticationScreen from "./screens/authentication/AuthenticationScreen";
import HomeScreen from "./screens/homeScreen/HomeScreen";
import LoginScreen from "./screens/loginScreen/LoginScreen";
import RegisterScreen from "./screens/registerScreen/RegisterScreen";
import TransactionScreen from "./screens/transaction/TransactionScreen";
import ProfileScreen from "./screens/ProfileScreen";
import StatisticsMonthlyDaily from "./screens/statisticsMonthlyDaily/StatisticsMonthlyDaily";
import StatisticsTypes from "./screens/statisticsTypes/StatisticsTypes";
import { useAuthRedirect } from './AppScript';
import { useThemeDetection } from "./scripts/UseThemeDetection";
import { useStatusBarColor } from "./scripts/UseStatusBarColor";

function App() {
    const [isDarkMode, setIsDarkMode] = useState(false);
    const location = useLocation();

    // Detect theme and update isDarkMode
    useThemeDetection(setIsDarkMode);

    // Update status bar color based on theme
    useStatusBarColor(isDarkMode);

    return (
        <div className="App">
            <AnimatePresence mode="wait">
                <Routes location={location} key={location.pathname}>
                    <Route path="/" element={<AuthRedirect />} />
                    <Route path="/auth" element={<AuthenticationScreen />} />
                    <Route path="/login" element={<LoginScreen />} />
                    <Route path="/register" element={<RegisterScreen />} />
                    <Route path="/home" element={<HomeScreen />} />
                    <Route path="/transaction" element={<TransactionScreen />} />
                    <Route path="/profile" element={<ProfileScreen />} />
                    <Route path="/statistics/monthly-daily" element={<StatisticsMonthlyDaily />} />
                    <Route path="/statistics/types" element={<StatisticsTypes />} />
                </Routes>
            </AnimatePresence>
        </div>
    );
}

function AuthRedirect() {
    useAuthRedirect();

    return null;
}

export default App;
