import { useEffect } from 'react'; // Import useEffect from React

export function useStatusBarColor(isDarkMode) {
    useEffect(() => {
        const updateStatusBarColor = (isDarkMode) => {
            console.log("Updating status bar color: ", isDarkMode ? '#000000' : '#ffffff');

            if (window.navigator.userAgent.match(/Android/i)) {
                const metaTag = document.querySelector('meta[name="theme-color"]');
                if (metaTag) {
                    console.log("Setting theme-color meta tag for Android: ", isDarkMode ? '#000000' : '#ffffff');
                    metaTag.setAttribute('content', isDarkMode ? '#000000' : '#ffffff');
                }
            } else if (window.navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
                const metaTag = document.querySelector('meta[name="apple-mobile-web-app-status-bar-style"]');
                if (metaTag) {
                    console.log("Setting apple status bar style for iOS: ", isDarkMode ? 'black-translucent' : 'default');
                    metaTag.setAttribute('content', isDarkMode ? 'black-translucent' : 'default');
                }
            }
        };

        updateStatusBarColor(isDarkMode);

        return () => {};
    }, [isDarkMode]);
}
