const token = localStorage.getItem('token');

export const checkTokenAuth = (navigate) => {
    if (!token) {
        navigate('/auth');
    }
};

export const checkTokenHome = (navigate) => {
    if (token) {
        navigate('/home');
    }
};