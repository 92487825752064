import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faGear, faAddressCard, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import './HeaderBar.css';

const iconMap = {
    faChevronLeft: faChevronLeft,
    faGear: faGear,
    faAddressCard: faAddressCard,
    faAngleLeft: faAngleLeft,
};

function HeaderBar({
                       title,
                       titleColor = '#000',
                       showLeftIcon,
                       showRightIcon,
                       leftIcon,
                       rightIcon,
                       leftIconColor = '#000',
                       rightIconColor = '#000',
                       onLeftIconClick,
                       onRightIconClick
                   }) {

    return (
        <div className="header-bar">
            {showLeftIcon ? (
                <button className="header-button" onClick={onLeftIconClick}>
                    <FontAwesomeIcon icon={iconMap[leftIcon]} style={{ color: leftIconColor }} />
                </button>
            ) : (
                <div className="header-button" />
            )}

            <h1 className="header-title" style={{ color: titleColor }}>{title}</h1>

            {showRightIcon ? (
                <button className="header-button" onClick={onRightIconClick}>
                    <FontAwesomeIcon icon={iconMap[rightIcon]} style={{ color: rightIconColor }} />
                </button>
            ) : (
                <div className="header-button" />
            )}
        </div>
    );
}

export default HeaderBar;
