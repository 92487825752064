import React, { useEffect } from 'react';
import HeaderBar from "../components/headerBar/HeaderBar";
import { useNavigate } from "react-router-dom";
import MainButton from "../components/buttons/mainButton/MainButton";

function ProfileScreen() {
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/auth');
        }
    }, [navigate]);

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/auth');
    };

    return (
        <div className="center-container">

            <HeaderBar
                title="Profile"
                titleColor="var(--tretyoariColor)"
                leftIcon="faAngleLeft"
                rightIcon="faGear"
                showLeftIcon={true}
                showRightIcon={false}
                leftIconColor="var(--tretyoariColor)"
                rightIconColor="#FFFFFF"
                borderColor="var(--tretyoariColor)"
                onLeftIconClick={() => navigate(-1)}
                onRightIconClick={() => console.log('Right icon clicked')}
            />

            <div style={{ height: '100px' }} />

            <MainButton
                title="Log Out"
                textColor="var(--mainTextColor)"
                bgColor="var(--tretyoariColor)"
                onClick={handleLogout}
            />
        </div>
    );
}

export default ProfileScreen;
