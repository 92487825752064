import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import HeaderBar from "../../components/headerBar/HeaderBar";
import ChartComponent from "../../components/chartComponent/ChartComponent";
import TransactionsList from "../../components/transactionsList/TransactionsList";

function StatisticsMonthlyDaily() {
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/auth');
        }
    }, [navigate]);

    return (
        <div>
            <HeaderBar
                title="New Transaction"
                titleColor="var(--tretyoariColor)"
                leftIcon="faChevronLeft"
                rightIcon="faGear"
                showLeftIcon={true}
                showRightIcon={false}
                leftIconColor="var(--tretyoariColor)"
                rightIconColor="#FFFFFF"
                borderColor="var(--tretyoariColor)"
                onLeftIconClick={() => navigate(-1)}
                onRightIconClick={() => console.log('Right icon clicked')}
            />

            <ChartComponent/>

            <TransactionsList/>

        </div>
    );
}

export default StatisticsMonthlyDaily;
