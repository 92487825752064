import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import HeaderBar from "../../components/headerBar/HeaderBar";
import MainButton from "../../components/buttons/mainButton/MainButton";
import MainInput from "../../components/mainInput/MainInput";
import IncomeExpenseToggle from "../../components/tonggle/IncomeExpenseToggle";
import Categories from "../../components/categories/Categories";
import './TransactionScreen.css';

function TransactionScreen() {
    const navigate = useNavigate();
    const [sold, setSold] = useState('');
    const [title, setTitle] = useState('');
    const [transactionType, setTransactionType] = useState('income');
    const [selectedCategory, setSelectedCategory] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/auth');
        }
    }, [navigate]);

    const handleAddTransaction = () => {
        const token = localStorage.getItem('token');

        const transactionData = {
            amount: sold,
            title: title,
            type: transactionType,
            category: selectedCategory
        };

        console.log('Transaction Data:', transactionData);

        fetch(`${process.env.REACT_APP_API_URL}/api/transactions`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(transactionData)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Failed to add transaction');
                }
            })
            .then(data => {
                console.log('Transaction added successfully:', data);
                navigate('/home');
            })
            .catch(error => {
                console.error('Error adding transaction:', error);
            });
    };

    return (
        <div className="transaction-screen-container">

            <HeaderBar
                title="New Transaction"
                titleColor="var(--tretyoariColor)"
                leftIcon="faChevronLeft"
                rightIcon="faGear"
                showLeftIcon={true}
                showRightIcon={false}
                leftIconColor="var(--tretyoariColor)"
                rightIconColor="#FFFFFF"
                borderColor="var(--tretyoariColor)"
                onLeftIconClick={() => navigate(-1)}
                onRightIconClick={() => console.log('Right icon clicked')}
            />

            <div className="transaction-container">
                <IncomeExpenseToggle onToggle={setTransactionType}/>
            </div>

            <div style={{height: '10px'}}/>

            <MainInput
                icon="faDollarSign"
                label="Amount"
                value={sold}
                onChange={(e) => setSold(e.target.value)}
            />

            <div style={{height: '30px'}}/>

            <MainInput
                icon="faTag"
                label="Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
            />

            <div style={{height: '30px'}}/>

            <Categories setSelectedCategory={setSelectedCategory}/>

            <div style={{height: '30px'}}/>

            <MainButton
                title="Add Transaction"
                textColor="var(--mainTextColor)"
                bgColor="var(--mainColor)"
                onClick={handleAddTransaction}
            />

        </div>
    );
}

export default TransactionScreen;
