import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

export function useAuthRedirect() {
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            navigate('/home');
        } else {
            navigate('/auth');
        }
    }, [navigate]);
}
