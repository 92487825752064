import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './TransactionsList.css';

function TransactionsList() {
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchTransactions = async () => {
            const token = localStorage.getItem('token');

            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/transactions/list`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setTransactions(response.data);
            } catch (err) {
                setError('Failed to load transactions');
            } finally {
                setLoading(false);
            }
        };

        fetchTransactions();
    }, []);

    if (loading) return <div className="loading">Loading transactions...</div>;
    if (error) return <div className="error">{error}</div>;

    return (
        <div className="transactions-list-container">
            <div className="scrollable-container">
                {transactions.map((transaction) => (
                    <div key={transaction.id} className="transaction-card">
                        <div className="transaction-header">
                            <span className="transaction-type">{transaction.type}</span>
                            <span className={`transaction-amount ${transaction.amount < 0 ? 'negative' : 'positive'}`}>
                                {transaction.amount} MDL
                            </span>
                        </div>
                        <div className="transaction-details">
                            <p className="transaction-title">{transaction.title}</p>
                            <p className="transaction-category">{transaction.category.name}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default TransactionsList;
