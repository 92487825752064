import React, { useState } from "react";
import {
    FaUtensils, FaLaptop, FaGraduationCap, FaMoneyBillWave,
    FaPlane, FaHeartbeat, FaDumbbell, FaTshirt, FaGamepad,
    FaCar, FaMusic, FaPaintBrush, FaFilm, FaSpa,
    FaLeaf, FaFutbol, FaDog, FaTools, FaCoffee,
    FaShoppingCart, FaHeart, FaBook, FaBriefcase, FaUser,
    FaClock, FaMapMarkerAlt, FaGift, FaAnchor, FaMobileAlt,
    FaSeedling, FaChalkboardTeacher, FaUserGraduate, FaDollarSign,
    FaTicketAlt, FaCamera, FaWrench, FaGlobe, FaBell,
    FaStar, FaCommentDollar, FaBed, FaShower, FaPizzaSlice
} from "react-icons/fa";
import "./Categories.css";

const categoriesData = [
    { id: 1, name: "Gastronomie", icon: <FaUtensils /> },
    { id: 2, name: "Tehnologie", icon: <FaLaptop /> },
    { id: 3, name: "Educație", icon: <FaGraduationCap /> },
    { id: 4, name: "Finanțe", icon: <FaMoneyBillWave /> },
    { id: 5, name: "Turism", icon: <FaPlane /> },
    { id: 6, name: "Sănătate", icon: <FaHeartbeat /> },
    { id: 7, name: "Fitness", icon: <FaDumbbell /> },
    { id: 8, name: "Modă", icon: <FaTshirt /> },
    { id: 9, name: "Gaming", icon: <FaGamepad /> },
    { id: 10, name: "Transport", icon: <FaCar /> },
    { id: 11, name: "Muzică", icon: <FaMusic /> },
    { id: 12, name: "Artă", icon: <FaPaintBrush /> },
    { id: 13, name: "Divertisment", icon: <FaFilm /> },
    { id: 14, name: "Wellness", icon: <FaSpa /> },
    { id: 15, name: "Eco", icon: <FaLeaf /> },
    { id: 16, name: "Sport", icon: <FaFutbol /> },
    { id: 17, name: "Animale", icon: <FaDog /> },
    { id: 18, name: "Auto", icon: <FaTools /> },
    { id: 19, name: "Cafea", icon: <FaCoffee /> },
    { id: 20, name: "Shopping", icon: <FaShoppingCart /> },
    { id: 21, name: "Romance", icon: <FaHeart /> },
    { id: 22, name: "Cărți", icon: <FaBook /> },
    { id: 23, name: "Serviciu", icon: <FaBriefcase /> },
    { id: 24, name: "Persoană", icon: <FaUser /> },
    { id: 25, name: "Timp", icon: <FaClock /> },
    { id: 26, name: "Localizare", icon: <FaMapMarkerAlt /> },
    { id: 27, name: "Cadouri", icon: <FaGift /> },
    { id: 28, name: "Navigație", icon: <FaAnchor /> },
    { id: 29, name: "Mobil", icon: <FaMobileAlt /> },
    { id: 30, name: "Plante", icon: <FaSeedling /> },
    { id: 31, name: "Cursuri", icon: <FaChalkboardTeacher /> },
    { id: 32, name: "Studii", icon: <FaUserGraduate /> },
    { id: 33, name: "Finanțe personale", icon: <FaDollarSign /> },
    { id: 34, name: "Bilete", icon: <FaTicketAlt /> },
    { id: 35, name: "Fotografie", icon: <FaCamera /> },
    { id: 36, name: "Unelte", icon: <FaWrench /> },
    { id: 37, name: "Călătorii globale", icon: <FaGlobe /> },
    { id: 38, name: "Notificări", icon: <FaBell /> },
    { id: 39, name: "Stele", icon: <FaStar /> },
    { id: 40, name: "Finanțare", icon: <FaCommentDollar /> },
    { id: 41, name: "Cazare", icon: <FaBed /> },
    { id: 42, name: "Îngrijire personală", icon: <FaShower /> },
    { id: 43, name: "Activități sociale", icon: <FaCommentDollar /> },
    { id: 44, name: "Evenimente", icon: <FaClock /> },
    { id: 45, name: "Recreație", icon: <FaGamepad /> },
    { id: 46, name: "Sporturi", icon: <FaFutbol /> },
    { id: 47, name: "Aventura", icon: <FaPlane /> },
    { id: 48, name: "Îngrijire", icon: <FaHeart /> },
    { id: 49, name: "Gătit", icon: <FaPizzaSlice /> }
];

const Categories = ({ setSelectedCategory }) => {
    const [selectedCategoryId, setSelectedCategoryId] = useState(null);

    const handleSelectCategory = (id, name) => {
        setSelectedCategoryId(id);
        setSelectedCategory({ id, name });
    };

    return (
        <div className="categories-container">
            {categoriesData.map((category) => (
                <div
                    key={category.id}
                    className={`category-item ${selectedCategoryId === category.id ? "selected" : ""}`}
                    onClick={() => handleSelectCategory(category.id, category.name)}
                >
                    <div className="category-icon">{category.icon}</div>
                    <div className="category-name">{category.name}</div>
                </div>
            ))}
        </div>
    );
};

export default Categories;
