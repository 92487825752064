import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import './ChartComponent.css';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const ChartComponent = () => {
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
    const [years, setYears] = useState([]);
    const [months, setMonths] = useState([]);
    const [dailyData, setDailyData] = useState([]);

    useEffect(() => {
        const token = localStorage.getItem('token');

        const fetchYears = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/transactions/year`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                const data = await response.json();
                setYears(data.years);
            } catch (error) {
                console.error('Error fetching years:', error);
            }
        };

        fetchYears();
    }, []);

    useEffect(() => {
        const token = localStorage.getItem('token');

        const fetchMonths = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/transactions/month/${selectedYear}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                const data = await response.json();
                setMonths(data.months);
            } catch (error) {
                console.error('Error fetching months:', error);
            }
        };

        if (selectedYear) {
            fetchMonths();
        }
    }, [selectedYear]);

    useEffect(() => {
        const token = localStorage.getItem('token');

        const fetchDailyData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/transactions/daily/${selectedYear}/${selectedMonth}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                const data = await response.json();
                setDailyData(data.daily_transactions);
            } catch (error) {
                console.error('Error fetching daily data:', error);
            }
        };

        if (selectedYear && selectedMonth) {
            fetchDailyData();
        }
    }, [selectedYear, selectedMonth]);

    const data = {
        labels: Object.keys(dailyData).map(day => day),
        datasets: [
            {
                label: 'Sold Zilnic',
                data: Object.values(dailyData),
                borderColor: '#5B8DEF',
                borderWidth: 2,
                fill: true,
                backgroundColor: 'rgba(91, 141, 239, 0.2)',
                tension: 0.4,
            },
        ],
    };

    const options = {
        responsive: true,
        scales: {
            x: {
                display: true,
                grid: {
                    display: false,
                },
            },
            y: {
                display: true,
                beginAtZero: true,
                grid: {
                    color: '#e5e5e5',
                },
            },
        },
        plugins: {
            legend: { display: false },
        },
    };

    return (
        <div className="chart-container">
            <div className="chart-content">
                {/*<h2 className="chart-title">Tendința soldului</h2>*/}
                <div className="month-buttons">
                    {months.map((month) => (
                        <button
                            key={month.value}
                            className={`month-button ${month.value === selectedMonth ? 'active' : ''}`}
                            onClick={() => setSelectedMonth(month.value)}
                        >
                            {month.label}
                        </button>
                    ))}
                </div>
                <Line data={data} options={options} />
            </div>
            <div className="year-buttons">
                {years.map((year) => (
                    <button
                        key={year}
                        className={`year-button ${year === selectedYear ? 'active' : ''}`}
                        onClick={() => setSelectedYear(year)}
                    >
                        {year}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default ChartComponent;
