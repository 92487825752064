import React from 'react';
import './MainButton.css';

function MainButton({ title, textColor, bgColor, onClick }) {
    return (
        <button
            className="custom-button"
            style={{ color: textColor, backgroundColor: bgColor }}
            onClick={onClick}
        >
            {title}
        </button>
    );
}

export default MainButton;
